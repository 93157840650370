<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <!-- <h1>ข้อตกลงความเป็นส่วนตัว</h1> -->
       <!-- <div class="title-line"></div> -->
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/quanbinhidbank/components/img/4_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`Chính sách bảo mật Q Money sẽ cho người dùng biết cách chúng tôi thu thập và sử dụng thông tin người dùng. Q Money không chia sẻ thông tin cá nhân của người dùng, chỉ thông tin người dùng để phân tích liên quan đến kinh doanh và đánh giá tín dụng cho vay.
Thông tin sản phẩm
Khoản vay lên tới 24,500,000 Đồng Việt Nam
Thời hạn vay: 93 ngày (ngắn nhất - kể cả ngắn hạn) - 365 ngày (dài nhất - kể cả dài hạn)
Lãi suất năm(APR): tối thiểu 2 %/năm - tối đa 30%/năm.
Phí giao dịch: 0
Ví dụ, khoản vay với lãi suất 14% có hạn mức là 5.000.000 VND và cần 120 ngày để trả lãi.
Vậy tổng tiền lãi là 5000000đ * 0.14/365 * 120 = 230136.
Vậy tiền lãi hàng tháng là 5000000 * 0.14/365 * 30 = 57534.
Số tiền thanh toán = 5000000 + Lãi = 5000000 +  230136 = 5230136
Số tiền hàng tháng = 5230136/120 * 30 = 130754
Thông tin được thu thập bởi Q Money
Khi chúng tôi cung cấp dịch vụ, chúng tôi có thể thu thập, lưu trữ và sử dụng các thông tin sau về bạn và yêu cầu ủy quyền của bạn. Nếu bạn không cung cấp thông tin liên quan, bạn có thể không thể đăng ký làm người dùng hoặc sử dụng một số dịch vụ chúng tôi cung cấp hoặc bạn không thể đạt được kết quả mong muốn của dịch vụ. Các dịch vụ của chúng tôi hoàn toàn tự nguyện và bạn không cần cung cấp thông tin đó trừ khi bạn chọn sử dụng dịch vụ Q Money của chúng tôi.
Thông tin cá nhân: bao gồm tên, số điện thoại di động, số ID, thông tin liên lạc của người thân, hình ảnh, địa chỉ công ty, số thẻ ngân hàng, chứng chỉ công việc, v.v.
Thông tin liên quan đến thiết bị của bạn, chẳng hạn như , địa chỉ IP, phiên bản Android, thông tin thiết bị mạng, số sê-ri thiết bị, danh sách cài đặt ứng dụng, thông tin sổ địa chỉ, SSID, v.v.
Thông tin vị trí (vị trí địa lý): Sau khi bạn cấp quyền, chúng tôi sẽ truy cập và truy xuất thông tin vị trí gần đúng của bạn, chủ yếu là kinh độ và vĩ độ. Dựa trên thông tin vị trí của bạn, chúng tôi sẽ cung cấp cho bạn các sản phẩm và dịch vụ phù hợp hơn với nhu cầu của bạn. Thông tin vị trí được sử dụng để đánh giá độ tin cậy của bạn. Xin lưu ý rằng nếu bạn vô hiệu hóa quyền này, bạn sẽ thay mặt mình thu hồi ủy quyền. Chúng tôi sẽ không thu thập và sử dụng thông tin cá nhân của bạn nữa và sẽ không thể cung cấp cho bạn các dịch vụ tương ứng. Tuy nhiên, quyết định tắt quyền của bạn sẽ không ảnh hưởng đến thông tin được thu thập và sử dụng trước đó dựa trên sự cho phép của bạn.
Thông tin ngân hàng: bao gồm tên, số tài khoản, v.v. Mục đích của việc thu thập thông tin này là để cải thiện các dịch vụ chúng tôi cung cấp cho bạn. Thông tin được cung cấp tùy thuộc vào cách bạn sử dụng nó để tham gia vào các dịch vụ của chúng tôi. Việc sử dụng các dịch vụ của chúng tôi là hoàn toàn tự nguyện và bạn không cần cung cấp thông tin đó trừ khi bạn chọn sử dụng dịch vụ Q Money của chúng tôi.
Thông tin đơn và giao dịch có nghĩa là chúng tôi phân tích dữ liệu giao dịch của các ứng dụng, khoản vay và các hình thức khác mà bạn đã hoàn thành trên Q Money và hình thành cơ sở dữ liệu tín dụng người dùng.
Dữ liệu hành vi đề cập đến hành vi hoạt động mà bạn đang sử dụng trên Q Money, chẳng hạn như đăng ký, đăng nhập, ứng dụng và các hoạt động liên quan khác, nhấp vào dữ liệu hành vi. Nếu cần, chúng tôi có thể truy cập các dịch vụ của nền tảng thống kê dữ liệu của bên thứ ba
Thông tin người liên hệ: Hệ thống yêu cầu cho phép quyền xác nhận danh sách Danh bạ của bạn để phát hiện các tham chiếu và tự động điền dữ liệu trong quá trình đăng ký khoản vay của bạn, giúp quá trình đăng kí được liền mạch. Danh sách liên hệ của bạn cũng có thể giúp chúng tôi đánh giá tình hình tài chính và khả năng trả nợ của bạn từ nhiều khía cạnh hơn. APP áp dụng công nghệ mã hóa dữ liệu 256-bit để đảm bảo an toàn thông tin của người dùng một cách nghiêm ngặt. Chúng tôi không bao giờ chia sẻ thông tin này với bất kỳ bên thứ ba nào
• Thư viện ảnh: Để xử lý đơn xin vay tiền của bạn, Q Money yêu cầu quyền truy cập vào thư viện thiết bị của bạn để bạn có thể tải lên các tài liệu và ảnh cần thiết. Chúng tôi chỉ truy cập dữ liệu này khi bạn chủ động tải nó lên, bảo vệ nghiêm ngặt quyền riêng tư của bạn và sẽ không sử dụng dữ liệu đó cho các mục đích khác. Thông tin của bạn sẽ được mã hóa và lưu trữ an toàn và sẽ không được chia sẻ với bất kỳ bên thứ ba nào. Bạn có thể quản lý quyền này bất kỳ lúc nào trong cài đặt thiết bị của mình.
Bạn có thể kiểm tra trạng thái mở quyền trong “Cài đặt” của thiết bị di động và bạn có thể quyết định bật hoặc tắt các quyền này. Khi bạn bật các quyền này, cũng đồng nghĩa bạn cho phép chúng tôi thu thập và sử dụng thông tin cá nhân để thực hiện các chức năng trên; nếu bạn tắt quyền này, chúng tôi sẽ không tiếp tục thu thập và sử dụng thông tin cá nhân của bạn, chúng tôi cũng không thể cung cấp cho bạn các tính năng được mô tả ở trên tương ứng với ủy quyền này. Việc bạn tắt quyền sẽ không ảnh hưởng đến việc tiến hành xử lý thông tin cá nhân trước đây dựa trên ủy quyền của bạn.
Máy ảnh: Với sự đồng ý của bạn, quyền này sẽ cho phép bạn tải lên ảnh hoặc tệp để xác minh rằng hành động được thực hiện bởi bạn chứ không phải ai khác.
2.Thông tin của bạn được cung cấp bởi các người liên quan hợp tác bên thứ ba
•Nếu cần, chúng tôi sẽ liên lạc với bên thứ ba của bạn (bao gồm nhưng không giới hạn ở văn phòng tín dụng quốc gia hợp pháp, nền tảng dịch vụ dữ liệu hợp pháp) để kiểm tra thông tin báo cáo tín dụng của bạn, như thông tin nhận dạng, công việc, v.v. Thông tin, hộ gia đình và thu nhập, thông tin dịch vụ an sinh xã hội, nợ phải trả và thông tin tình trạng tín dụng.
3.Q Money quản lý thông tin cá nhân của bạn như thế nào?
thông tin chúng tôi thu thập sẽ được lưu trữ và quản lý cho các mục đích sau: Để kiểm tra so sánh giữa thông tin bạn cung cấp và thông tin thực tế, vui lòng đảm bảo rằng không ai được phép sử dụng danh tính của bạn.
Xác định khả năng của bạn để có được một khoản vay theo các điều khoản hiện hành.
Xác nhận rằng thông tin bạn cung cấp là chính xác trong quá trình đăng ký khoản vay.
Hiểu cách bạn truy cập và sử dụng các dịch vụ của chúng tôi để tối ưu hóa và nâng cao quy trình kinh doanh và thiết kế app của chúng tôi.
Giúp chúng tôi hiểu sở thích hoạt động và hành vi của người dùng, giúp chúng tôi cung cấp dịch vụ cho vay chính xác hơn.
Giúp chúng tôi đảm bảo sự phát triển có trật tự của kinh doanh cho vay và thu hồi nợ.
4.Q Money bảo vệ thông tin cá nhân của người dùng như thế nào?
Q Money tìm cách bảo vệ thông tin của bạn bằng công nghệ mới nhất. Chúng tôi sẽ tuân thủ các điều khoản hiện hành để đảm bảo thông tin của bạn được giữ bí mật và được duy trì và quyền truy cập bị hạn chế để bảo vệ bạn khỏi mọi hình thức lừa đảo. Bạn có trách nhiệm bảo vệ mã truy cập tài khoản và ID người dùng và mật khẩu của mình để đảm bảo rằng những người dùng khác không thể truy cập vào tài khoản củabạn.
Về thông tin của bạn, Q Money không thể đảm bảo tính bảo mật của dữ liệu được truyền qua ứng dụng của chúng tôi. Khi chúng tôi nhận được dữ liệu hoặc thông tin của bạn, chúng tôi sẽ sử dụng các tính năng và quy trình bảo mật để ngăn chặn truy cập trái phép.
Về thông tin của bạn, Q Money không thể đảm bảo tính bảo mật của dữ liệu được truyền qua ứng dụng của chúng tôi. Khi chúng tôi nhận được dữ liệu hoặc thông tin của bạn, chúng tôi sẽ sử dụng các tính năng và quy trình bảo mật để ngăn chặn truy cập trái phép.
Q Money tuân thủ đầy đủ và tuân thủ luật pháp và quy định bảo vệ dữ liệu hiện hành tại Việt Nam.
Thông tin người dùng mà Q Money có thể chia sẻ với bên thứ ba
Q Money sẽ tiết lộ thông tin cá nhân của người dùng của chúng tôi cho các nhà cung cấp dịch vụ, người vay và thành viên nhóm của chúng tôi, những người giúp chúng tôi thực hiện một số phần trong hoạt động kinh doanh của mình, bao gồm dịch vụ, thanh toán, thủ tục phòng chống gian lận,Q Money cũng sẽ chia sẻ bất kỳ thông tin người dùng hợp pháp và liên quan đến tòa án nào với các bên thứ ba để ngăn ngừa tổn thất tài chính, báo cáo các hoạt động bất hợp pháp bị nghi ngờ hoặc điều tra vi phạm.
Nếu khoản vay của bạn quá hạn nghiêm trọng hoặc bạn qua đời đột ngột, bạn sẽ không thể trả nợ gốc và lãi của khoản vay. Chúng tôi sẽ tiết lộ thông tin người dùng, thông tin cho vay và thông tin quá hạn cho công chúng hoặc các tổ chức đối tác trong phạm vi được luật pháp và quy định cho phép.
Chúng tôi sẽ tiết lộ thông tin hồ sơ của bạn, thông tin mượn và thông tin quá hạn khi bạn vi phạm hợp đồng hoặc hành vi gây ảnh hưởng đến các hoạt động Q Money ảnh hưởng xấu đến người dùng khác và khởi kiện.
Khoản vay của bạn đã quá hạn nghiêm trọng hoặc bạn qua đời đột ngột , tiền gốc và tiền lãi của khoản vay không được hoàn trả. Nếu cần thiết, chủ nợ ủy thác việc thu thập để cung cấp thông tin người dùng,thông tin quá hạn cho người nhận ủy thác.
Khi bạn đăng ký các dịch vụ liên quan cho đối tác bên thứ ba thông qua ứng dụng Q Money, chúng tôi sẽ cung cấp thông tin người dùng của bạn cho các bên thứ ba theo ủy quyền của bạn.
Chúng tôi và các chi nhánh của chúng tôi có thể cung cấp thông tin cá nhân của bạn cho các chi nhánh, đối tác và nhà cung cấp dịch vụ bên thứ ba, nhà thầu và đại lý của chúng tôi (ví dụ: nhà cung cấp dịch vụ liên lạc gửi tin nhắn văn bản hoặc thông báo thay mặt chúng tôi, cung cấp cho chúng tôi Chia sẻ nhà cung cấp dịch vụ bản đồ cho dữ liệu vị trí (họ có thể không thuộc thẩm quyền của bạn) để chúng tôi có thể cung cấp hoặc cải thiện dịch vụ của chúng tôi cho bạn.
Khi việc kinh doanh của chúng tôi tiếp tục phát triển, chúng tôi và các chi nhánh của chúng tôi có thể tham gia vào việc sáp nhập, mua lại, chuyển nhượng tài sản hoặc giao dịch tương tự và thông tin cá nhân của bạn có thể được chuyển giao như một phần của các giao dịch đó.
Q Money sẽ không bán hoặc cho thuê bất kỳ Dữ liệu thông tin người dùng nào cho bất kỳ bên thứ ba nào cho bất kỳ mục đích nào, trừ khi chúng tôi áp dụng thay đổi chính sách và sẽ thông báo cho bạn về bất kỳ thay đổi nào.
Thay đổi chính sách bảo mật
Do những thay đổi về luật pháp và quy định, và để theo kịp sự phát triển mới và xu hướng có thể có trên Internet, chúng tôi có thể sửa đổi chính sách này theo thời gian. Do đó, chúng tôi bảo lưu quyền, theo quyết định riêng của chúng tôi, để thực hiện các sửa đổi đó, vì những sửa đổi này dẫn đến việc giảm đáng kể các quyền của bạn theo Chính sách quyền riêng tư này. Trong trường hợp như vậy, nếu bạn tiếp tục sử dụng dịch vụ của chúng tôi, bạn đồng ý bị ràng buộc bởi Chính sách quyền riêng tư sửa đổi.
Nếu có bất kỳ thay đổi nào đối với chính sách bảo mật của chúng tôi, chúng tôi sẽ gửi thông báo thay đổi qua tin nhắn hoặc tin nhắn SMS, cửa sổ bật lên trang ứng dụng.
7.liên hệ chúng tôi
Email liên hệ: office@quanbinhidbank.com
Địa chỉ: 3 Nguyen Trai St., Hai Dinh Ward, Dong Hoi Township, Dong Hoi
ĐT: +84 581212941`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: "";
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 3700px;
  background-color: "";
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  /* margin-top: 50px; */
 margin-left: 490px;
 color: #3F4842;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
display: inline-block;
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
.imgBox2{
  transform: scale(90%);
  margin: -150px auto 0;
  height: 3200px;
  /* box-shadow:0 6px 14px rgba(0, 0, 0, 0.25); */
}

.title-line{
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0BAF60;
  margin: 0 500px;
}
/* .img1{
  margin-top: 50px;
  padding: 0 200px;
} */
</style>